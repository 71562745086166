import React from 'react'
import { Container } from 'reactstrap'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'

import img from '../../static/img/LS_404_Logo@2x.png'

const Main = styled.main`
  img {
    max-width: 150px;
  }
  h1 {
    color: ${scssVars.darkBrown};
    font-size: 24px;
    font-weight: 700;
  }
  .btn {
    width: auto;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Main className="py-5 bg-clr-cararra">
      <Container className="text-center my-5">
        <img src={img} className="mb-4" />
        <h1>Repair Emergency!</h1>
        <p>
          The page you requested is unavailable.
          <br />
          Use the menu above to continue browsing.
        </p>
        <Link className="btn btn-primary mt-3 px-5" to="/">
          Return To Home Page
        </Link>
      </Container>
    </Main>
  </Layout>
)

export default NotFoundPage
